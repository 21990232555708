import {
  Logo,
  Logo2,
  EasyCheckoutIcon,
  CheckIcon,
  NavIcon,
  PaymentLinkIcon,
  RecurringIcon,
  CheckoutIcon,
  ApiIcon,
  ArrowRight,
  Security,
  Facebook,
  Instagram,
  Linkedin,
  PinTress,
  Logo3,
  DashboardIcon,
  ComplianceIcon,
  InvoiceIcon,
  CustomersIcon,
  PaymentIcon,
  TransactionsIcon,
  BalanceIcon,
  DocumentIcon,
  SupportIcon,
  SettingIcon,
  ToggleIcon,
  SearchIcon,
  BellIcon,
  HelpIcon,
  ChevronDropdown,
  AdminIcon2,
  FilterIcon,
  SortIcon,
  WebIcon,
  EmailIcon,
  CallIcon,
  UploadIcon,
  DownloadIcon,
  TotalIcon,
  IncreaseIcon,
  AdminStatusIcon
} from "../assets/icons/icons";
import { GoPrimitiveDot as Dot } from "react-icons/go";
import { MdOutlineMenuOpen as MenuIcon, MdDeleteForever as DeleteIcon } from "react-icons/md";
import { RiMenuFoldLine as MenuIcon2 } from "react-icons/ri";
import { RiMenuUnfoldLine as MenuIcon3 } from "react-icons/ri";
import { BiDotsHorizontalRounded as ActionIcon } from "react-icons/bi";
import { FiEdit as EditIcon } from "react-icons/fi";

export const Icons = {
  AdminIcon2,
  Logo,
  Logo3,
  NavIcon,
  PaymentLinkIcon,
  RecurringIcon,
  CheckoutIcon,
  ApiIcon,
  EasyCheckoutIcon,
  Dot,
  CheckIcon,
  ArrowRight,
  Security,
  Logo2,
  Facebook,
  Instagram,
  Linkedin,
  PinTress,
  MenuIcon,
  MenuIcon2,
  MenuIcon3,
  DashboardIcon,
  ComplianceIcon,
  InvoiceIcon,
  CustomersIcon,
  PaymentIcon,
  TransactionsIcon,
  BalanceIcon,
  DocumentIcon,
  SupportIcon,
  SettingIcon,
  ToggleIcon,
  SearchIcon,
  BellIcon,
  HelpIcon,
  ChevronDropdown,
  ActionIcon,
  FilterIcon,
  SortIcon,
  WebIcon,
  EmailIcon,
  CallIcon,
  UploadIcon,
  DownloadIcon,
  TotalIcon,
  IncreaseIcon,
  AdminStatusIcon,
  DeleteIcon ,
  EditIcon
};
