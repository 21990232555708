import React, { useEffect, useState } from "react";
import { Styled } from "../../../constants/Styled";
import {  TransaSearch, TransaSort } from "../../../common";
import { Button, Dropdown, Menu, Modal, Table } from "antd";
import { getInvestmentColumns } from "../../../data/Investment.Data";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllInvestments } from "../../../state/slice/Investments.Slice";
import { exportToExcel, exportToPDF } from "../../../utils/Utils";
import { Icons } from "../../../constants/Icons";
import FundingDetails from "./FundingDetails";
import AddAddress from "./Add_Address";


const Investments = () => {
  const dispatch = useDispatch();
  const { investments, loading } = useSelector((state) => state.investments);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isSearchQuery, setIsSearchQuery] = useState("");
  // const [isFilter, setFilter] = useState(null);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);


  useEffect(() => {
    dispatch(
      fetchAllInvestments({
        page: currentPage,
        limit: pageSize,
        search: isSearchQuery,
      })
    );
  }, [dispatch, isSearchQuery, currentPage, pageSize]);

  const allInvestments = investments?.data?.data;

  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  // const handleFilterChange = (selectedValue) => {
  //   setFilter(selectedValue);
  // };


  const handleView = (customerId) => {
    setSelectedCustomerId(customerId);
    setIsViewDetails(!isViewDetails);
  };

  const renderActionMenu = (customerId) => {
    return (
      <Menu className="actions__keys">
        <Menu.Item key="view" onClick={() => handleView(customerId)}>
          View
        </Menu.Item>
      </Menu>
    );
  };

  const actionColumn = {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (_, record, index) => (
      <Dropdown
        overlay={renderActionMenu(record?.id, index)}
        placement="bottomRight"
        arrow
      >
        <Icons.ActionIcon
          color="#101323"
          size={25}
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>
    ),
    align: "center",
  };

  const invest_col = getInvestmentColumns(isSearchQuery);

  return (
    <div>
      <Styled.Wrapper>
        <Styled.Section sx="compliance__wrapper">
          <TransaSearch
            placeholder="Search by ID / Purpose"
            value={isSearchQuery}
            onChange={(e) => setIsSearchQuery(e.target.value)}
          />
       

          <Styled.Wrapper sx="flex justify-center items-center gap-[8px] ">

            <TransaSort name="PDF" onClick={() => exportToPDF(allInvestments, "Investment Report", invest_col, "landscape")} />
            <TransaSort name="EXCEL" onClick={() => exportToExcel(allInvestments, "Investment Report", invest_col)} />
            <Button size="large" type="primary" onClick={() => setIsOpen(!isOpen)}>Add Address</Button>
          </Styled.Wrapper>
        </Styled.Section>

        <Table
          type="primary"
          columns={invest_col.concat(actionColumn)}
          dataSource={allInvestments}
          loading={loading}
          rowKey="id"
          rowSelection="checked"
          pagination={{
            total: investments?.data?.pagination?.totalPage * pageSize,
            pageSize: pageSize,
            current: currentPage,
            showSizeChanger: true,
            onChange: handlePageChange,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} investments`,
            pageSizeOptions: ["10", "20", "30", "40", "50", "60", "70", "80", "100",
            ],
          }}
          className="centered-pagination"
          scroll={{ x: true }}
        />
      </Styled.Wrapper>

      {isViewDetails && selectedCustomerId &&
        <Modal
          title="Funding Details"
          open={isViewDetails}
          footer={null}
          width={400}
          onCancel={handleView}
        >
          <FundingDetails id={selectedCustomerId} />
        </Modal>}

        {isOpen && (
          <Modal
          title="Add Wallet Address"
          onCancel={() => setIsOpen(false)}
          open={isOpen}
          width={400}
          footer={null}
          >
              <AddAddress />
          </Modal>
        )}

    </div>
  );
};

export default Investments;
