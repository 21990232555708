import React from 'react'
import { UserAcceptInvite } from '../components'

const AcceptInvite = () => {
  return (
    <div>
        <UserAcceptInvite />
    </div>
  )
}

export default AcceptInvite