import React from 'react'
import { UserChangePassword } from '../components'

const ChangePassword = () => {
  return (
    <div>
        <UserChangePassword />
    </div>
  )
}

export default ChangePassword