

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from "../../app/config";


const initialState = {
  customers: [],
  loading: false,
  error: null,
};

export const fetchAllCustomers = createAsyncThunk(
  'pitcher/all/customers',
  async ({page, limit, search, filter }, { rejectWithValue }) => {
    try {
      // const filt = filter === null ? '' : `&filter=${filter}`;
      const response = await userRequest.get(`/console/fetch-user?page=${page}&limit=${limit}&search=${search}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload;
      })
      .addCase(fetchAllCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })   
  },
});

export default customersSlice.reducer;
