// InvestmentColumns.js

import { formatAmount, formatDateAndTime } from "../utils/Utils";


export const getInvestmentColumns = (isSearchQuery) => {
    
  const invest_col = [
    {
      title: "S/N",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },

    {
      title: "Currency Type",
      dataIndex: "currency_type",
      sorter: (a, b) => a.currency_type - b.currency_type,
      filteredValue: [isSearchQuery],
      onFilter: (value, record) => {
        return (
          String(record.currency_type)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.amount).toLowerCase().includes(value.toLowerCase()) ||
          String(formatDateAndTime(record.createdAt)).toLowerCase().includes(value.toLowerCase())
        );
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      render: formatAmount,
      sorter: (a, b) => a.amount - b.amount,
    },

    {
      title: "Funding Type",
      dataIndex: "is_delux",
      render: (is_delux) => (
        <div
          className={`status-before-content ${
            is_delux === true ? "Delux" : "Other"
          }`}
        >
          {is_delux === true ? "Delux" : "Other"}
        </div>
      ),
      sorter: (a, b) => a.is_delux - b.is_delux,
      align: "center",
    },


    {
      title: "Status",
      dataIndex: "is_approved",
      render: (is_approved) => (
        <div
          className={`status-before-content ${
            is_approved === true ? "Approved" : "Pending"
          }`}
        >
          {is_approved === true ? "Approved" : "Pending"}
        </div>
      ),
      sorter: (a, b) => a.is_approved - b.is_approved,
      align: "center",
    },

    {
      title: "Time / Date",
      dataIndex: "createdAt",
      render: formatDateAndTime,
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
  ];

  return invest_col;
};




export const filterOptions = [
  { value: null, label: "All" },
  { value: true, label: "Verified" },
  { value: false, label: "Pending" },
];


export const removeFilterOption = (options) => options.filter(option => option.value !== "all");
export const filteredOptions = removeFilterOption(filterOptions);



export const funding_data = (data) => [
  {
      label: "Full Name",
      value: data?.funding_user?.full_name
  },

  {
      label: "Phone Number",
      value: data?.funding_user?.phone_number
  },

  {
      label: "Referral Code",
      value: data?.funding_user?.refferal_code
  },

  {
      label: "Level",
      value: data?.funding_user?.level
  },

  {
    label: "Currency Type",
    value: data?.funding?.currency_type
},


{
  label: "Delux",
  value: data?.funding?.is_delux ? "Yes, Delux" : "No, Other"
},


{
  label: "Funding Proof",
  value: <a download href={data?.funding?.fundProof}><img src={data?.funding?.fundProof} alt="" width={100} height={50}/></a>
},

  {
      label: "Status",
      value: <span className={`status-before-content ${data?.funding?.is_approved ? "Approved" : "Pending"}`}>{data?.funding?.is_approved ? "Approved" : "Pending"}</span>
  },

  {
      label: "Amount",
      value: formatAmount(data?.funding?.amount)
  },

  {
      label: "Time / Date",
      value: formatDateAndTime(data?.funding?.createdAt)
  },
]
