import React, { useEffect } from 'react'
import { Styled } from '../../../../constants/Styled'
import { Icons } from '../../../../constants/Icons'
import { TransaDesc } from '../../../../common'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCustomers } from '../../../../state/slice/SingleCustomer.Slice';
import { formatDateAndTime } from '../../../../utils/Utils';

const DetailsDetails = ({ customerId }) => {
    const dispatch = useDispatch();
    const { singleCustomer } = useSelector((state) => state.singleCustomer);

    useEffect(() => {
        const payload = customerId;
        dispatch(fetchSingleCustomers(payload));
    },[dispatch, customerId])

    const cusDetail = singleCustomer?.data; 

  return (
    <>
        <Styled.Wrapper sx="merchant__details__wrapper">
            <Styled.Section sx="merchant__details__header">
                <Styled.Text sx="merchant__name">@{cusDetail?.full_name}</Styled.Text>
                <Styled.Text sx="merchant__status">Status: <Styled.Span sx={`status-before-content ${cusDetail?.is_approved ? "Approved" : "Pending"}`}>{cusDetail?.is_approved ? "Approved" : "Pending"}</Styled.Span></Styled.Text>
            </Styled.Section>

            <Styled.Section sx="merchant__details__header pt-[8px]">
                <Styled.Text sx="merchant__address"><Icons.EmailIcon /> {cusDetail?.refferal_code ?? "N/A"}</Styled.Text>
                <Styled.Text sx="merchant__address"><Icons.CallIcon /> {cusDetail?.phone_number ?? "N/A"}</Styled.Text>
            </Styled.Section>

            <Styled.Section sx="pt-[1rem]">
                <TransaDesc title="FIAT" desc={cusDetail?.wallet && cusDetail.wallet[0]?.wallet_balance} />
                <TransaDesc title="USDT" desc={cusDetail?.wallet && cusDetail?.wallet[1]?.wallet_balance} />
                <TransaDesc title="TRIAL" desc={cusDetail?.wallet && cusDetail?.wallet[2]?.wallet_balance} />
                <TransaDesc title="Delux" desc={cusDetail?.task?.delux ?? 0} />
                <TransaDesc title="Total Set" desc={cusDetail?.task?.total_set ?? "N/A"} />
                <TransaDesc title="Current Set" desc={cusDetail?.task?.current_set ?? "N/A"} />
                <TransaDesc title="Task Done" desc={cusDetail?.task?.task_done ?? "N/A"} />
                <TransaDesc title="Pending Withdrawal" desc={cusDetail?.task?.pending_withdrawal ? "Yes" : "No"} />
                <TransaDesc title="Total Earning" desc={cusDetail?.sub_balance?.total_earning ?? "N/A"} />
                <TransaDesc title="Holding Balance" desc={cusDetail?.sub_balance?.holding_balance ?? "N/A"} />
                <TransaDesc title="Currency Type" desc={cusDetail?.sub_balance?.currency_type ?? "N/A"} />
                <TransaDesc title="Bank Name" desc={cusDetail?.bank_information?.bank_name ?? "N/A"} />
                <TransaDesc title="Account Number" desc={cusDetail?.bank_information?.account_number ?? "N/A"} />
                <TransaDesc title="Account Name" desc={cusDetail?.bank_information?.account_name ?? "N/A"} />
                <TransaDesc title="USDT ERC20"  desc={cusDetail?.bank_information?.usdt_erc20 ?? "N/A"} />
                <TransaDesc title="USDT TRC20"  desc={cusDetail?.bank_information?.usdt_trc20 ?? "N/A"} />
                <TransaDesc title="Date Registered" desc={formatDateAndTime(cusDetail?.createdAt)}/>
            </Styled.Section>


        </Styled.Wrapper>
    </>
  )
}

export default DetailsDetails