import { Button } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { approvedWithdrawal } from "../state/slice/withdrawRequest.Slice"

const ModalDetails = ({ data, withdrawId, datas }) => {
    const dispatch = useDispatch()
    const [ isLoading, setIsLoading ] = useState(false)

    const withdrawRequest = datas?.withdraw?.wallet === null;


    const handleApproval = async () => {
        setIsLoading(true);
        try {
            const payload = {
                id: withdrawId
            }
            const res = await dispatch(approvedWithdrawal(payload)).unwrap();
            setIsLoading(false);
            toast.success(res?.message)
        } catch (err) {
            setIsLoading(false);
            toast.error(err.message || err?.response?.message)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className='withdraw__container'>
            {data.map((item, index) => (
                <div className='withdraw__details' key={index}>
                    <p>{item?.label}</p>
                    <span>{item?.value}</span>
                </div>
            ))}

            {withdrawRequest ? null :  <Button loading={isLoading} onClick={() => handleApproval()} size='large' className='w-full mt-5' type='primary'>Approved Withdrawer Request</Button>}
           
        </div>
    )
}

export default ModalDetails