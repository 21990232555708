import React, { useEffect, useState } from 'react'
import { ModalDetails } from '../../../common'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSingleFunding } from '../../../state/slice/single_funding.Slice';
import { ApproveFunding } from "../../../state/slice/approve.funding.Slice"
import { funding_data } from '../../../data/Investment.Data';
import { Button } from 'antd';
import { toast } from 'react-toastify';

const FundingDetails = ({ id }) => {
    const dispatch = useDispatch();
    const { singleFunding } = useSelector(state => state.singleFunding)
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        dispatch(fetchSingleFunding(id))
    },[dispatch, id])

    const fundingDetails = singleFunding?.data
    const data = funding_data(fundingDetails)


    const handleApproved = async () => {
        setLoading(true);
        try {
          const res = await dispatch(ApproveFunding(id)).unwrap();
          setLoading(false);
          toast.success(res.message)
        } catch (err) {
          setLoading(false);
          toast.error(err?.data?.message ||  err.message)
        } finally {
          setLoading(false);
        }
    }

  return (
    <div>
        <ModalDetails data={data}/>
        <Button disabled={fundingDetails?.funding?.is_approved} type='primary' size="large"  className='w-full mt-5' loading={loading} onClick={() => handleApproved()}>Approved Funding</Button>
    </div>
  )
}

export default FundingDetails