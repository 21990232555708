

export const chart_data = [
    {
      name: 'Jan',
      amt: 0.01,
    },
    {
      name: 'Feb',
      amt: 700,
    },
    {
      name: 'Mar',
      amt: 600,
    },
    {
      name: 'April',
      amt: 400,
    },
    {
      name: 'May',
      amt: 1200,
    },
    {
      name: 'June',
      amt: 1500,
    },
    {
      name: 'July',
      amt: 1800,
    },
  
    {
      name: 'Aug',
      amt: 2100,
    },
  
    {
      name: 'Sept',
      amt: 2400,
    },
  
    {
      name: 'Oct',
      amt: 2700,
    },
  
    {
      name: 'Nov',
      amt: 2000,
    },
  
    {
      name: 'Dec',
      amt: 0.01,
    },
  ];

