// ALL IMAGES

import AuthBg from "../assets/img/auth__bg.jpg";
import UserImg from "../assets/img/Avatar.png";
import BusLogo from "../assets/img/bus_logo.png";
import ActiveAdmin from "../assets/img/ActiveAdmin.png";
import Logo from "../assets/img/smeandmore_logo.svg"

export const Images = {
  AuthBg,
  UserImg,
  BusLogo,
  ActiveAdmin,
  Logo
};
